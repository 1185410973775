'use client'

import { type LottieRefCurrentProps } from 'lottie-react'
import dynamic from 'next/dynamic'
import { useTranslations } from 'next-intl'
import { useRef } from 'react'

import defaultAnimationJson from '@/assets/animations/lottie-error-animation.json'

const Lottie = dynamic(async () => await import('lottie-react'), { ssr: false })

type BackgroundColor = 'bg-greyblue-90' | 'bg-greyblue-85'

interface AnimationProps {
  /**
   * A custom lottie animation json
   */
  json?: Record<string, any>

  /**
   * A custom speed for the animation
   *
   * @default 1
   */
  speed?: number
}

export interface ErrorScreenProps {
  /**
   * Depending on the area of the error component, a different background color might be necessary
   *
   * @default bg-greyblue-90
   */
  backgroundColor?: BackgroundColor

  /**
   * The general title of the error component.
   *
   * @default t('error.defaultTitle')
   */
  title?: string

  /**
   * The general message of the error component.
   *
   * @default t('error.defaultMessage')
   */
  message?: string

  /**
   * Optional buttons for the error message, e. g. `<RefreshButton />`
   *
   * @default null
   */
  buttons?: JSX.Element

  /**
   * A custom lottie animation configuration that will be shown instead of the default one.
   */
  customAnimation?: AnimationProps
}

interface ErrorAnimationProps {
  customAnimation?: AnimationProps
}

const ErrorAnimation = ({ customAnimation }: ErrorAnimationProps): JSX.Element => {
  const lottieRef = useRef<LottieRefCurrentProps>(null)
  const delayBetweenLoops = 5000

  return (
    <Lottie
      animationData={customAnimation?.json ?? defaultAnimationJson}
      lottieRef={lottieRef}
      onDOMLoaded={() => {
        if (lottieRef.current != null) {
          lottieRef.current.setSpeed(customAnimation?.speed ?? 1.5)
        }
      }}
      onLoopComplete={() => {
        if (lottieRef.current != null) {
          lottieRef.current.stop()
        }

        setTimeout(() => {
          if (lottieRef.current != null) {
            lottieRef.current.play()
          }
        }, delayBetweenLoops)
      }}
    />
  )
}

const ErrorScreen = (props: ErrorScreenProps): JSX.Element => {
  const t = useTranslations()
  const {
    backgroundColor = 'bg-greyblue-90',
    title = t('error.defaultTitle'),
    message = t('error.defaultMessage'),
    buttons,
    customAnimation
  } = props

  return (
    <>
      <div className={`flex grow items-center justify-center p-64 ${backgroundColor}`}>
        <div className="flex w-full max-w-4xl items-end gap-88">
          <div className="grow">
            <h2 className="text-b36 text-white">{title}</h2>
            <div className="mt-12 text-14 text-greyblue-40">{message}</div>

            {buttons != null && <div className="mt-32 flex items-center gap-16">{buttons}</div>}
          </div>

          <div className="hidden lg:block">
            <div className="size-[168px]">
              <ErrorAnimation customAnimation={customAnimation} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ErrorScreen
